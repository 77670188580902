import React, { useState } from 'react'
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  AreaChart,
  Area,
  Tooltip
} from 'recharts'
import { useHistoricalPnL } from '../../hooks/query/chart/useHistoricalPnL'
import { ChartRange } from '../../constants/enum'
import TimeRangeSelector from './TimeRangeSelector'
import vaultStore from '../../store/vault'
import { toPriceString } from '../../utils/number'
import { toDateString, toTimeString } from '../../utils/string'

type Props = {
  asset: string
  vaultId: number
}

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="text-white text-xs green-gradient rounded-lg p-1">
        <p>${toPriceString(payload[0].value)}</p>
      </div>
    )
  }
  return null
}

const HistoricalPnLChart = ({ asset, vaultId }: Props) => {
  const [chartRange, setChartRange] = useState(ChartRange.Day)
  const { subVaultIndex } = vaultStore()
  const historicalPnL = useHistoricalPnL(
    asset,
    vaultId,
    subVaultIndex,
    chartRange,
    1000
  )

  return (
    <div className="rounded-lg bg-secondaly w-full h-[370px] p-2">
      <div className="flex justify-between p-2 px-4">
        <p className="text-lg font-semibold">
          {subVaultIndex >= 0
            ? 'Historical Profit & Loss'
            : 'Historical pVault Value'}
        </p>
        <TimeRangeSelector
          chartRange={chartRange}
          onSelect={(chartRange: ChartRange) => {
            setChartRange(chartRange)
          }}
        />
      </div>
      <div>
        {historicalPnL.isLoading ? (
          <div className="flex items-center justify-center h-60">
            <div className="animate-ping h-4 w-4 green-gradient rounded-full"></div>
          </div>
        ) : (
          <ResponsiveContainer width="100%" height={310}>
            <AreaChart data={historicalPnL.data.values}>
              <defs>
                <linearGradient
                  id="blueChartGradation"
                  x1="0%"
                  y1="0%"
                  x2="100%"
                  y2="100%"
                >
                  <stop offset="0%" stopColor="#5259FA1A" />
                  <stop offset="100%" stopColor="#5259FA00" />
                </linearGradient>
              </defs>
              <CartesianGrid stroke="#4c525f" />
              <XAxis
                dataKey="timestamp"
                tick={{ fontSize: 12 }}
                tickFormatter={timestamp =>
                  chartRange === ChartRange.Hour ||
                    chartRange === ChartRange.Day
                    ? toTimeString(timestamp)
                    : toDateString(timestamp)
                }
              />
              <YAxis
                dataKey="value"
                domain={[
                  historicalPnL.data.domainLow,
                  historicalPnL.data.domainHigh
                ]}
                tick={{ fontSize: 12 }}
                tickFormatter={(value: number) => `$${toPriceString(value)}`}
              />
              <Tooltip
                cursor={{ stroke: 'white', strokeWidth: 2, fill: 'none' }}
                content={<CustomTooltip />}
              />
              <Area
                type="monotone"
                dataKey="value"
                stroke="#257EFF"
                strokeWidth={2}
                fill="url(#blueChartGradation)"
                activeDot={{
                  r: 8,
                  stroke: 'white',
                  strokeWidth: 4,
                  fill: '#2E3239'
                }}
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  )
}

export default HistoricalPnLChart
