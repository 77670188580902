import React, { useState } from 'react'
import { useLPTokenPrice } from '../../hooks/query/pool/usePoolStatus'
import { toPriceString } from '../../utils/number'
import { TradeButtonArea } from '../trade/TradeButtonArea'
import { useApproveMutation } from '../../hooks/contracts/useApprove'
import { useAddressesAnyway } from '../../hooks/useAddress'
import { toScaled, toUnscaled } from '../../utils/bn'
import { useERC20BalanceQuery } from '../../hooks/query/balance'
import { useAllowanceQuery } from '../../hooks/query/allowance'
import { useDeposit, useWithdraw } from '../../hooks/contracts/useDeposit'
import { TradeType } from '../../constants/enum'

enum Tab {
  Add,
  Remove
}

type Props = {
  asset: number
}

const Liquidity = ({ asset }: Props) => {
  const [tab, setTab] = useState(Tab.Add)
  const [depositAmount, setDepositAmount] = useState(0)
  const lpTokenPrice = useLPTokenPrice(
    asset,
    tab === Tab.Add ? depositAmount : -depositAmount
  )
  const addresses = useAddressesAnyway()

  const lpTokenBalanceQuery = useERC20BalanceQuery(
    addresses.Perpetuals[0].LPToken
  )
  const allowanceQuery = useAllowanceQuery(
    addresses.Perpetuals ? addresses.Perpetuals[asset].PerpetualMarket : '',
    addresses.QuoteToken
  )

  const approve = useApproveMutation()
  const deposit = useDeposit()
  const withdraw = useWithdraw()

  const onApprove = async () => {
    await approve.mutateAsync({
      address: addresses.QuoteToken,
      spender: addresses.Perpetuals[asset].PerpetualMarket,
      amount: toScaled(depositAmount, 6)
    })
  }

  const onDeposit = async () => {
    await deposit.mutateAsync(toScaled(depositAmount, 6))
  }

  const onWithdraw = async () => {
    await withdraw.mutateAsync(toScaled(depositAmount, 6))
  }

  const isEnoughUSDCApproved = () => {
    return allowanceQuery.gte(toScaled(depositAmount || 0, 6))
  }

  return (
    <div className="rounded-lg bg-secondaly w-full h-full p-6 flex items-center justify-center">
      <div className="w-[296px]">
        <div className="flex items-center justify-center py-2">
          <div className="flex items-center justify-between w-full bg-third rounded-lg">
            <div
              className={`p-2 w-28 rounded-lg cursor-pointer text-center ${
                tab === Tab.Add ? 'final-gradient' : ''
              }`}
              onClick={() => {
                setTab(Tab.Add)
              }}
            >
              Add
            </div>
            <div
              className={`p-2 w-28  rounded-lg cursor-pointer text-center ${
                tab === Tab.Remove ? 'final-gradient' : ''
              }`}
              onClick={() => {
                setTab(Tab.Remove)
              }}
            >
              Remove
            </div>
          </div>
        </div>
        <div className="py-2">
          <div>
            <input
              className="w-full rounded-md bg-third text-white p-2"
              type="number"
              defaultValue={0}
              onChange={e => {
                setDepositAmount(Number(e.target.value))
              }}
            />
          </div>
        </div>
        <div>
          <div className="space-y-3">
            <div className="flex justify-between">
              <label>Total</label>
              <p>
                {toPriceString(toUnscaled(lpTokenBalanceQuery, 8))} LP Token
              </p>
            </div>
            <div className="flex justify-between">
              <label>Total(USDC)</label>
              <p>
                {lpTokenPrice.isSuccess
                  ? toPriceString(
                      toUnscaled(
                        lpTokenBalanceQuery
                          .mul(lpTokenPrice.data.lpTokenPrice)
                          .div('10000000000000000'),
                        8
                      )
                    )
                  : 0}{' '}
                USDC
              </p>
            </div>
            <div className="flex justify-between">
              <label>{tab === Tab.Add ? 'Mint' : 'Burn'}</label>
              <p>
                {lpTokenPrice.isSuccess ? lpTokenPrice.data.lpTokenAmount : 0}{' '}
                LP Token
              </p>
            </div>
            <div className="flex justify-between">
              <label>{tab === Tab.Add ? 'Deposit' : 'Receive'}</label>
              <p>{depositAmount} USDC</p>
            </div>
            <div className="flex items-center justify-center">
              {tab === Tab.Add ? (
                <TradeButtonArea
                  marginAmount={depositAmount}
                  onApprove={onApprove}
                  onTrade={onDeposit}
                  hasEnoughUSDC={true}
                  isEnoughUSDCApproved={isEnoughUSDCApproved()}
                  insufficientLiquidity={false}
                  isMarginEnough={true}
                  isExceededSoftLimit={false}
                  isExceededPoolLimit={false}
                  tradeType={TradeType.Deposit}
                  isSubVaultsLoaded={true}
                  isTradeAmountsZero={false}
                />
              ) : (
                <button
                  className="w-full h-[50px] p-2 final-gradient rounded-lg text-xs font-semibold"
                  onClick={onWithdraw}
                >
                  Withdraw
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Liquidity
