import { useQuery } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useChainId, useIsSupportedChain } from '../../network'
import { Product } from '../../../constants/enum'
import { getAssetIndex } from '../../../utils/asset'
import { usePoolStatus } from '../pool/usePoolStatus'
import {
  calculateMinCollateral,
  calculatePositionValue,
  getVaultPositions
} from '../../../utils/helpers/vaultHelpers'
import { getHighAndLow } from '../../../utils/helpers/chartHelper'
import { toFixedNumber } from '../../../utils/number'
import { useVaultStatus, VaultStatus } from '../useVaultStatus'
import { useCurrentEthPrice } from '../../usePrice'
import { PerpetualMarket__factory } from '../../../typechain'
import { useAddresses } from '../../useAddress'
import { toScaled } from '../../../utils/bn'
import { TradeFormData } from '../../../store/trade'
import { useEffect, useState } from 'react'
import { getTradePrice } from '../../../wrapper'
import { useMinMargin } from '../useMinCollateral'
import { TRADE_FEE_1, TRADE_FEE_2 } from '../../../constants'

type SpotPositionValue = {
  price: number
  netValue: number
  value: number
  minCollateral: number
}

export type SpotPnLChartData = {
  values: SpotPositionValue[]
  domainLow: number
  domainHigh: number
}

function calculatePnLData(
  vaultStatus: VaultStatus | null,
  spotPrice: number,
  futureFundingRate: number,
  squaredFundingRate: number,
  futureFundingFeePerPosition: number,
  squaredFundingFeePerPosition: number,
  tradeField: TradeFormData,
  minMargin: number
): SpotPositionValue[] {
  const basePrice = Math.floor(spotPrice / 100) * 100
  const step = 100
  const prices = Array.from(
    { length: 50 },
    (v, i) => (i - 24) * step + basePrice
  )

  const vaultPosition = getVaultPositions(vaultStatus)

  const positionPerpetuals = vaultPosition.positions
  const entryFundingFee = vaultPosition.entryFundingFees
  const tradePrice0 = spotPrice * (1 + futureFundingRate)
  const tradePrice1 = (spotPrice * spotPrice * (1 + squaredFundingRate)) / 10000
  const entryPrices = vaultPosition.entryPrices
  const entryPricesForCurrent = [tradePrice0, tradePrice1]
  const entryFundingFeeForCurrent = [
    futureFundingFeePerPosition,
    squaredFundingFeePerPosition
  ]

  return prices.map(price => {
    const minCollateral = calculateMinCollateral(
      [
        positionPerpetuals[0] + tradeField.tradeAmount0,
        positionPerpetuals[1] + tradeField.tradeAmount1
      ],
      futureFundingRate,
      squaredFundingRate,
      price,
      minMargin
    )

    const futureIndexPrice = price
    const squaredIndexPrice = (price * price) / 10000

    let tradePrice0 = futureIndexPrice * (1 + futureFundingRate)
    let tradePrice1 = squaredIndexPrice * (1 + squaredFundingRate)
    if (positionPerpetuals[0] > 0) {
      tradePrice0 -= futureIndexPrice * TRADE_FEE_1
    } else if (positionPerpetuals[0] < 0) {
      tradePrice0 += futureIndexPrice * TRADE_FEE_1
    }
    if (positionPerpetuals[1] > 0) {
      tradePrice1 -= squaredIndexPrice * TRADE_FEE_2
    } else if (positionPerpetuals[1] < 0) {
      tradePrice1 += squaredIndexPrice * TRADE_FEE_2
    }

    const netPositionValue = calculatePositionValue(
      [positionPerpetuals[0], positionPerpetuals[1]],
      entryPrices,
      tradePrice0,
      tradePrice1,
      entryFundingFee,
      futureFundingFeePerPosition,
      squaredFundingFeePerPosition,
      vaultStatus ? vaultStatus.positionUsdc : 0
    )

    const positionValue = calculatePositionValue(
      [tradeField.tradeAmount0, tradeField.tradeAmount1],
      entryPricesForCurrent,
      tradePrice0,
      tradePrice1,
      entryFundingFeeForCurrent,
      futureFundingFeePerPosition,
      squaredFundingFeePerPosition,
      tradeField.marginAmount
    )

    return {
      price,
      netValue: toFixedNumber(netPositionValue + positionValue),
      value: toFixedNumber(positionValue),
      minCollateral
    }
  })
}

export function useSpotPnLChartData(
  asset: string,
  vaultId: number,
  subVaultIndex: number,
  tradeField: TradeFormData
) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const chainId = useChainId()
  const supportedChain = useIsSupportedChain()
  const assetIndex = getAssetIndex(asset)
  const vaultStatus = useVaultStatus(assetIndex, vaultId)
  const ethPrice = useCurrentEthPrice()
  const poolStatus = usePoolStatus(assetIndex)
  const [chartData, setChartData] = useState<SpotPnLChartData>({
    values: [],
    domainLow: 0,
    domainHigh: 0
  })
  const minMargin = useMinMargin(assetIndex)

  const chartDataQuery = useQuery(
    ['spot_pnl_chart_data', chainId, vaultId, subVaultIndex, tradeField],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!addresses) throw new Error('addresses not set')
      if (!ethPrice.isSuccess) throw new Error('ethPrice is not loaded')
      if (!poolStatus.isSuccess) throw new Error('poolStatus is not loaded')
      if (!vaultStatus.isLoaded) throw new Error('vaultStatus is not loaded')
      if (!minMargin.isSuccess) throw new Error('minMargin is not loaded')

      const contract = PerpetualMarket__factory.connect(
        addresses.Perpetuals[assetIndex].PerpetualMarket,
        library
      )

      const getFundingRate = async (
        product: Product,
        tradeAmount: number,
        subVaultIndex: number
      ) => {
        if (
          tradeAmount === 0 &&
          (!vaultStatus.data || !vaultStatus.data.subVaults[subVaultIndex])
        ) {
          return 0
        }

        const tradeResult = await getTradePrice(
          contract,
          product,
          toScaled(
            tradeAmount !== 0
              ? tradeAmount
              : vaultStatus.data
              ? -vaultStatus.data.subVaults[subVaultIndex].positionPerpetuals[
                  product
                ]
              : 0,
            8
          )
        )
        return tradeResult.fundingRate
      }

      const futureFundingRate = await getFundingRate(
        Product.FUTURE,
        tradeField.tradeAmount0,
        subVaultIndex
      )
      const squaredFundingRate = await getFundingRate(
        Product.SQUARED,
        tradeField.tradeAmount1,
        subVaultIndex
      )

      const pnlData = calculatePnLData(
        vaultStatus.data,
        ethPrice.data,
        futureFundingRate,
        squaredFundingRate,
        poolStatus.data.status[Product.FUTURE].amountFundingPaidPerPosition,
        poolStatus.data.status[Product.SQUARED].amountFundingPaidPerPosition,
        tradeField,
        minMargin.data
      )

      const highAndLow = getHighAndLow(
        pnlData
          .map(pnLDataItem => pnLDataItem.netValue)
          .concat(pnlData.map(pnLDataItem => pnLDataItem.minCollateral)),
        1000
      )

      return {
        values: pnlData,
        domainHigh: highAndLow.domainHigh,
        domainLow: highAndLow.domainLow
      }
    },
    {
      enabled:
        supportedChain &&
        !!library &&
        !!addresses &&
        ethPrice.isSuccess &&
        poolStatus.isSuccess &&
        vaultStatus.isLoaded &&
        minMargin.isSuccess
    }
  )

  useEffect(() => {
    if (chartDataQuery.isSuccess) {
      setChartData(chartDataQuery.data)
    }
  }, [chartDataQuery.isSuccess, chartDataQuery.data])

  return chartData
}
