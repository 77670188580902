import { BigNumber } from "ethers"
import { PerpetualMarket } from "./typechain"
import { toUnscaled } from "./utils/bn"

/**
 * Fetch trade price from smart contract
 * @param contract 
 * @param productId 
 * @param tradeAmount 
 * @param lastFundingRate 
 * @returns 
 */
export async function getTradePrice(
  contract: PerpetualMarket,
  productId: number,
  tradeAmount: BigNumber
) {
  try {
    const result = await contract.getTradePrice(productId, tradeAmount)
    return {
      productId,
      tradePrice: toUnscaled(result.tradePrice, 8),
      fundingRate: toUnscaled(result.fundingRate, 16),
      totalFee: toUnscaled(result.totalFee, 8),
      isAvailable: true
    }  
  }catch(e) {
    return {
      productId,
      tradePrice: 0,
      fundingRate: 0,
      totalFee: 0,
      isAvailable: false
    }    
  }
}