import { useQuery } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { BigNumber, ethers } from 'ethers'
import { useChainId, useIsSupportedChain } from '../network'
import { toScaled } from '../../utils/bn'
import { useAddresses } from '../useAddress'
import { PerpetualMarket__factory } from '../../typechain'
import { getIV } from '../../utils/bs'
import { Product } from '../../constants/enum'
import { useBlockNumber } from '../blockNumber'
import { getTradePrice } from '../../wrapper'

export type TradePrice = {
  productId: Product
  tradePrice: number
  fundingRate: number
  estimatedFundingRate: number
  impliedVolatility: number
  totalFee: number
  isAvailable: boolean
}

/**
 * fetch trade price
 * @param productId
 * @param tradeAmount
 * @returns
 */
export function useTradePrice(
  asset: number,
  productId: number,
  tradeAmount: number
) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const chainId = useChainId()
  const supportedChain = useIsSupportedChain()
  const blockNumber = useBlockNumber()

  return useQuery(
    ['trade_price', chainId, blockNumber, productId, tradeAmount],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!addresses) throw new Error('addresses not set')

      const contract = PerpetualMarket__factory.connect(
        addresses.Perpetuals[asset].PerpetualMarket,
        library
      )

      const result = await getTradePrice(
        contract,
        productId,
        tradeAmount !== 0 ? toScaled(tradeAmount, 8) : BigNumber.from(1)
      )

      const tradePrice: TradePrice = {
        productId,
        tradePrice: result.tradePrice,
        fundingRate: result.fundingRate,
        estimatedFundingRate: result.fundingRate,
        impliedVolatility: getIV(result.fundingRate),
        totalFee: result.totalFee,
        isAvailable: result.isAvailable
      }

      return tradePrice
    },
    {
      enabled: !!library && supportedChain && !!addresses
    }
  )
}
