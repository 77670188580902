export type Addresses = {
  Perpetuals: PerpetualAddresses[]
  Multicall2: string
  QuoteToken: string
  Hedger: string
  FeePool: string
}

export type PerpetualAddresses = {
  PriceFeed: string
  PerpetualMarket: string
  LPToken: string
  UnderlyingToken: string
  VaultNFT: string
}

export const rinkebyArbitrum = {
  Perpetuals: [
    {
      PriceFeed: '0x5f0423B1a6935dc5596e7A24d98532b67A0AeFd8',
      PerpetualMarket: '0x7f5ca0AECca0044A538255C84322Ff3FA5129dCA',
      LPToken: '0x7c46bABaD7d4dE3279363B602CDf35D3514d1DD2',
      UnderlyingToken: '0xB47e6A5f8b33b3F17603C83a0535A9dcD7E32681',
      VaultNFT: '0xA4bFf5301b07671Db218244334AB226feEF21e92'
    }
  ],
  Multicall2: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  QuoteToken: '0xF61Cffd6071a8DB7cD5E8DF1D3A5450D9903cF1c',
  Hedger: '0x0000000000000000000000000000000000000000',
  FeePool: '0xF4C21eA5fa73Ffae3D0d896c983EF761f3970caf'
}

export const arbitrum = {
  Perpetuals: [
    {
      PriceFeed: '0x639Fe6ab55C921f74e7fac1ee960C0B6293ba612',
      PerpetualMarket: '0xc7ec02AEeCdC9087bf848c4C4f790Ed74A93F2AF',
      LPToken: '0x17F63ee2551C517260E956B0913daa2cDAC301E4',
      UnderlyingToken: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
      VaultNFT: '0x8F80FA7860b3C96bcD91db63526A510d93B36E7C'
    }
  ],
  Multicall2: '0x842ec2c7d803033edf55e478f461fc547bc54eb2',
  QuoteToken: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
  Hedger: '0xc622fd7adfe9aafa97d9bc6f269c186f07b59f0f',
  FeePool: '0xE17B52958aa4a3cEa4202B9893A0C7ae5bDaCEa5'
}

export const NETWORKS: { [key: number]: Addresses } = {
  [421611]: rinkebyArbitrum,
  [42161]: arbitrum
}

export const UNI_ETH_USDC_POOL_ADDRESS = [
  '0x8ad599c3a0ff1de082011efddc58f1908eb6e6d8',
  '0x88e6a0c2ddd26feeb64f039a2c41296fcb3f5640'
]
