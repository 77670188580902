import React from 'react'
import { useLocation, Link } from 'wouter'
import ConnectButton from './ConnectButton'
import VaultSelector from './VaultSelector'
import { ModeSwitch } from './ModeSwitch'
import logo from '../../assets/logo.svg'
import { useBlockTimestamp } from '../../hooks/useBlockTimestamp'
import dayjs from 'dayjs'

enum SelectableMenu {
  Trade,
  Positions,
  LP
}

function getSelectableMenu(path: string) {
  if (path.indexOf('pro') >= 0) {
    return SelectableMenu.Trade
  } else if (path.indexOf('trade') >= 0) {
    return SelectableMenu.Trade
  } else if (path.indexOf('position') >= 0) {
    return SelectableMenu.Positions
  }
  return SelectableMenu.LP
}

const Header = () => {
  const timestamp = useBlockTimestamp()
  const [path, setLocation] = useLocation()

  const isPro = path.indexOf('pro') >= 0
  const selected = getSelectableMenu(path)

  return (
    <>
      <div className="test-sm">This app is deprecated. New Version is <a className="test-sm" href="https://v202app.predy.finance/">here</a>.</div>
      <div className="flex flex-wrap-reverse md:flex-nowrap items-center justify-between bg-secondaly w-full h-[132px] md:h-[60px] px-4">
        <div className="flex items-center">
          <div className="pl-24 hidden md:block">
            <img src={logo} alt="Logo"></img>
          </div>
          <div className="md:pl-24">
            <div>
              <ul className="flex flex-row items-center space-x-8">
                {isPro ? (
                  <>
                    <li
                      className={`${selected === SelectableMenu.Trade
                        ? 'text-white'
                        : 'text-subtext'
                        }`}
                    >
                      <Link href="/pro/eth">Trade</Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li
                      className={`${selected === SelectableMenu.Trade
                        ? 'text-white'
                        : 'text-subtext'
                        }`}
                    >
                      <Link href="/lite/trade/eth">Trade</Link>
                    </li>
                    <li
                      className={`${selected === SelectableMenu.Positions
                        ? 'text-white'
                        : 'text-subtext'
                        }`}
                    >
                      <Link href="/lite/position/eth">Positions</Link>
                    </li>
                  </>
                )}
                <li className="text-subtext">
                  <a
                    href="https://predy.gitbook.io/predy-v2-documentation/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Doc
                  </a>
                </li>
              </ul>
              {selected === SelectableMenu.Trade ||
                selected === SelectableMenu.Positions ? (
                <span
                  className={`relative block h-[1px] w-[85px] final-gradient ${selected === SelectableMenu.Trade
                    ? '-translate-x-[22px]'
                    : selected === SelectableMenu.Positions
                      ? 'translate-x-[70px]'
                      : 'translate-x-[152px]'
                    } top-[21px] transition ease-in-out`}
                ></span>
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <div>
            <div className="px-2">
              <VaultSelector />
            </div>
          </div>
          <div>
            <div className="px-2">
              <ConnectButton />
            </div>
          </div>
          <div>
            <div className="px-2">
              <ModeSwitch
                isPro={isPro}
                onChange={(isPro: boolean) => {
                  if (isPro) {
                    setLocation('/pro/eth')
                  } else {
                    setLocation('/lite/trade/eth')
                  }
                }}
              ></ModeSwitch>
            </div>
          </div>
          <div>
            <div className="hidden lg:block w-20 text-[10px]">
              {dayjs(timestamp * 1000).format('DD MMMM YYYY HH:mm:ss Z')}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
