import { useQuery } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { BigNumber, ethers } from 'ethers'
import { useChainId, useIsSupportedChain } from '../network'
import { toUnscaled } from '../../utils/bn'
import { useAddresses } from '../useAddress'
import { ERC20__factory, VaultNFT__factory } from '../../typechain'
import { useCurrentEthPrice } from '../usePrice'
import {
  AggregatedVolume,
  AGGREGATED_VOLUME_QUERY,
  MetricsEntities,
  METRICS_QUERY
} from '../../queries/MetricsQuery'
import { PredyClient } from '../../utils/apollo-client'
import { DEFAULT_CHAIN } from '../../constants'
import { Product } from '../../constants/enum'
import { toTimeString2 } from '../../utils/string'

export type ProtocolStatus = {
  tvl: number
  numVaults: number
  metrics: {
    volume: number
    notionalVolume: number
    openLong: number
    openShort: number
  }[]
  hedgerBalance: number
  feePoolBalance: number
  lpTokenTotalSupply: number
}

export function useProtocolStatus(assetIndex: number) {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const chainId = useChainId()
  const supportedChain = useIsSupportedChain()
  const ethPrice = useCurrentEthPrice()

  return useQuery(
    ['protocol', chainId, assetIndex],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!addresses) throw new Error('addresses not set')
      if (!ethPrice.isSuccess) throw new Error('ethPrice is not loaded')

      const client = supportedChain
        ? PredyClient[chainId]
        : PredyClient[DEFAULT_CHAIN]

      const response: { data: MetricsEntities } = await client.query({
        query: METRICS_QUERY,
        variables: {
          first: 2
        }
      })

      const vaultNFT = VaultNFT__factory.connect(
        addresses.Perpetuals[assetIndex].VaultNFT,
        library
      )
      const usdc = ERC20__factory.connect(addresses.QuoteToken, library)
      const underlying = ERC20__factory.connect(
        addresses.Perpetuals[assetIndex].UnderlyingToken,
        library
      )
      const lpToken = ERC20__factory.connect(
        addresses.Perpetuals[assetIndex].LPToken,
        library
      )

      const numVaults = await vaultNFT.nextId()
      const balanceUSDC = await usdc.balanceOf(
        addresses.Perpetuals[assetIndex].PerpetualMarket
      )
      const balanceUnderlying = await underlying.balanceOf(
        addresses.Perpetuals[assetIndex].PerpetualMarket
      )

      const hedgerBalance = await usdc.balanceOf(addresses.Hedger)

      const feePoolBalance = await usdc.balanceOf(addresses.FeePool)

      const totalSupply = await lpToken.totalSupply()

      const protocolStatus: ProtocolStatus = {
        tvl:
          toUnscaled(balanceUSDC, 6) +
          toUnscaled(balanceUnderlying, 18) * ethPrice.data,
        numVaults: numVaults.toNumber() - 1,
        metrics: response.data.metricsEntities.map((m, i) => ({
          volume: toUnscaled(BigNumber.from(m.volume), 8),
          notionalVolume: toUnscaled(BigNumber.from(m.notionalVolume), 8),
          openLong: toUnscaled(BigNumber.from(m.openLong), 8),
          openShort: toUnscaled(BigNumber.from(m.openShort), 8)
        })),
        hedgerBalance: toUnscaled(hedgerBalance, 6),
        feePoolBalance: toUnscaled(feePoolBalance, 6),
        lpTokenTotalSupply: toUnscaled(totalSupply, 8)
      }

      return protocolStatus
    },
    {
      enabled: !!library && ethPrice.isSuccess && !!addresses
    }
  )
}

export function useVolumes() {
  const { account, library } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const chainId = useChainId()
  const supportedChain = useIsSupportedChain()

  return useQuery(
    ['volumes', chainId],
    async () => {
      if (!account) throw new Error('Account not set')
      if (!library) throw new Error('library not set')
      if (!addresses) throw new Error('addresses not set')

      const client = supportedChain
        ? PredyClient[chainId]
        : PredyClient[DEFAULT_CHAIN]

      const futureAggregatedVolumes: { data: AggregatedVolume } =
        await client.query({
          query: AGGREGATED_VOLUME_QUERY,
          variables: {
            first: 7,
            interval: 'DAILY',
            productId: Product.FUTURE
          }
        })
      const squaredAggregatedVolumes: { data: AggregatedVolume } =
        await client.query({
          query: AGGREGATED_VOLUME_QUERY,
          variables: {
            first: 7,
            interval: 'DAILY',
            productId: Product.SQUARED
          }
        })

      const convert = (volume: any) => {
        return {
          volume: toUnscaled(BigNumber.from(volume.volume), 8),
          notionalVolume: toUnscaled(BigNumber.from(volume.notionalVolume), 8),
          timestamp: volume.closeTimestamp,
          time: toTimeString2(Number(volume.closeTimestamp))
        }
      }

      return {
        future: futureAggregatedVolumes.data.aggregatedVolumes.map(convert),
        squared: squaredAggregatedVolumes.data.aggregatedVolumes.map(convert)
      }
    },
    {
      enabled: !!library && !!addresses
    }
  )
}
