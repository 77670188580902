import React, { ReactNode } from 'react'
import cardETH from '../assets/cards/Chart_ETH.svg'
import cardETH2 from '../assets/cards/Chart_ETH2.svg'
import cardCrab from '../assets/cards/Chart_Crab.svg'
import cardUni from '../assets/cards/Chart_Univ3.svg'
import cardDelta from '../assets/cards/Formula_Delta.svg'
import cardGamma from '../assets/cards/Formula_Gamma.svg'
import iconEth from '../assets/icons/Icon_ETH.svg'
import iconEth2 from '../assets/icons/Icon_ETH2.svg'
import iconDelta from '../assets/icons/Icon_Delta.svg'
import iconUni from '../assets/icons/Icon_Uni.svg'
import iconCrab from '../assets/icons/Icon_Crab.svg'
import iconGamma from '../assets/icons/Icon_Gamma.svg'
import { CardType } from '../constants/enum'
import cardStore from '../store/card'
import tradeStore from '../store/trade'
import mixpanel from '../mixpanel'

type Props = {
  chart: string
  icon: string
  title: string | ReactNode
  description: string
  selected?: boolean
  onClick: () => void
}

const Card = ({
  chart,
  icon,
  title,
  description,
  selected,
  onClick
}: Props) => {
  return (
    <div
      onClick={onClick}
      className={`rounded-lg p-4 h-[245px] ${
        selected ? 'final-gradient-outline' : 'bg-secondaly'
      }`}
    >
      <div className="flex justify-between">
        <div>
          <div className="text-base font-medium">{title}</div>
        </div>
        <div>
          <img
            src={icon}
            alt={'ETH'}
            className={icon === iconEth2 ? '-mt-[7px]' : ''}
          ></img>
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <img className="my-2" src={chart} alt={'ETH'}></img>
      </div>
      <p className="text-[10px] text-subtext">{description}</p>
    </div>
  )
}

const CardList = () => {
  const { setFields } = tradeStore()
  const { selectedCard, selectCard } = cardStore()

  function wrappedSelectCard(cardType: CardType) {
    selectCard(cardType)

    setFields({ tradeAmount0: 0, tradeAmount1: 0, marginAmount: 0 })

    mixpanel.track('click_strategy', {
      strategyId: cardType
    })
  }

  return (
    <div className="grid grid-cols-3 grid-rows-2 gap-4">
      <div className="col-span-1">
        <Card
          chart={cardETH2}
          icon={iconEth2}
          title={
            <p>
              (ETH)<sup className="text-xs">2</sup>
            </p>
          }
          description={'Leveraged Power Perpetual'}
          selected={selectedCard === CardType.ETH2}
          onClick={() => {
            wrappedSelectCard(CardType.ETH2)
          }}
        />
      </div>
      <div className="col-span-1">
        <Card
          chart={cardETH}
          icon={iconEth}
          title={'ETH'}
          description={'Leveraged Perpetual'}
          selected={selectedCard === CardType.ETH}
          onClick={() => {
            wrappedSelectCard(CardType.ETH)
          }}
        />
      </div>
      <div className="col-span-1">
        <Card
          chart={cardDelta}
          icon={iconDelta}
          title={'Delta'}
          description={'Greeks'}
          selected={selectedCard === CardType.DELTA}
          onClick={() => {
            wrappedSelectCard(CardType.DELTA)
          }}
        />
      </div>
      <div className="col-span-1">
        <Card
          chart={cardUni}
          icon={iconUni}
          title={'UniV3 LP Hedge'}
          description={'Hedge for Uniswap V3'}
          selected={selectedCard === CardType.UNI_HEDGE}
          onClick={() => {
            wrappedSelectCard(CardType.UNI_HEDGE)
          }}
        />
      </div>
      <div className="col-span-1">
        <Card
          chart={cardCrab}
          icon={iconCrab}
          title={'Crab Strategy'}
          description={'High APY Vault'}
          selected={selectedCard === CardType.CRAB}
          onClick={() => {
            wrappedSelectCard(CardType.CRAB)
          }}
        />
      </div>
      <div className="col-span-1">
        <Card
          chart={cardGamma}
          icon={iconGamma}
          title={'Gamma'}
          description={'Greeks'}
          selected={selectedCard === CardType.GAMMA}
          onClick={() => {
            wrappedSelectCard(CardType.GAMMA)
          }}
        />
      </div>
    </div>
  )
}

export default CardList
