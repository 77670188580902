import React, { useEffect, useState } from 'react'
import iconCrab from '../../../assets/icons/Icon_Crab.svg'
import iconMagnifyingGlass from '../../../assets/MagnifyingGlass.png'
import iconCross from '../../../assets/Cross.png'
import { AmountForm } from '../AmountForm'
import modalStore from '../../../store/modal'
import { useCurrentEthPrice } from '../../../hooks/usePrice'
import { getNetDelta, getGamma, getDeltaOfSquared } from '../../../utils/bs'
import { useTradePrice } from '../../../hooks/query/useTradePrice'
import { Product } from '../../../constants/enum'
import { getAmount } from '../../../utils'
import {
  toFundingRateString,
  toGreeksString,
  toPriceString
} from '../../../utils/number'

type Props = {
  asset: number
  amounts: number[]
  tradePrices: number[]
  fundingRates: number[]
  onChange: (amount0: number, amount1: number) => void
}

export const CrabForm = ({
  asset,
  amounts,
  tradePrices,
  fundingRates,
  onChange
}: Props) => {
  const [crabAmount, setCrabAmount] = useState(0)
  const tradePrice = useTradePrice(asset, Product.SQUARED, amounts[1])
  const { setOpen } = modalStore()
  const ethPrice = useCurrentEthPrice()

  return (
    <div className="space-y-4">
      <div className="flex flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <img src={iconCrab} className="h-[50px]"></img>
          <div>
            <p>Crab Strategy</p>
          </div>
        </div>
        <div className="flex space-x-2">
          <img
            className="cursor-pointer"
            src={iconMagnifyingGlass}
            onClick={() => {
              setOpen(true)
            }}
          />
          <img
            className="cursor-pointer"
            src={iconCross}
            onClick={() => {
              setOpen(false)
            }}
          />
        </div>
      </div>
      <div>
        <AmountForm
          amount={crabAmount}
          onChange={amount => {
            if (ethPrice.isSuccess && tradePrice.isSuccess) {
              setCrabAmount(amount)
              const squaredAmount = -amount
              const delta = getDeltaOfSquared(
                squaredAmount,
                tradePrice.data.fundingRate,
                ethPrice.data
              )
              onChange(-delta, squaredAmount)
            }
          }}
          step={1}
          name={'crabAmount'}
        />
      </div>
      <div className="space-y-2 text-xs">
        <div className="flex justify-between">
          <p>
            Amount (ETH)<sup>2</sup>, ETH:
          </p>
          <p>
            {getAmount(amounts[1])}
            {', '}
            {getAmount(amounts[0])}
          </p>
        </div>
        <div className="flex justify-between">
          <p>
            Entry Price (ETH)<sup>2</sup>, ETH:
          </p>
          <p>
            ${toPriceString(tradePrices[1])}, ${toPriceString(tradePrices[0])}
          </p>
        </div>
        <div className="flex justify-between">
          <p>Estimated Funding Rate:</p>
          <p>
            {toFundingRateString(fundingRates[1])}% ,{' '}
            {toFundingRateString(fundingRates[0])}%
          </p>
        </div>
        <div className="flex justify-between">
          <p>Delta, Gamma:</p>
          <p>
            {toGreeksString(
              getNetDelta(
                amounts[0],
                amounts[1],
                fundingRates[0],
                fundingRates[1],
                ethPrice.data || 0
              )
            )}
            , {toGreeksString(getGamma(amounts[1], fundingRates[1]))}
          </p>
        </div>
        <div>{}</div>
      </div>
    </div>
  )
}
